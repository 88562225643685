export default class DropDown {
	constructor(element, onChange) {
		if (!element) throw Error('No dropdown element provided');

		this.element = element;

		this.config = {
			valueSelector: '[js-element~="customSelectValue"]',
			optionSelector: '[js-element~="customSelectOption"]',
			wrapperSelector: '[js-element~="customSelectWrapper"]',
		};

		this.currentValue = null;
		this.options = this.element.querySelectorAll(this.config.optionSelector);
		this.val = this.element.querySelector(this.config.valueSelector);
		this.wrapper = this.element.querySelector(this.config.wrapperSelector);
		this.onChange = onChange;
		this.focusedOption = 0;
		this.onSelectStateHandler = this.onSelectState.bind(this);

		this.init();
	}

	init = () => {
		// make it a11y
		this.val.setAttribute('aria-expanded', 'false');
		this.val.setAttribute('aria-controls', this.wrapper.id);
		this.val.setAttribute('aria-haspopup', 'listbox');
		this.val.setAttribute('role', 'combobox');
		this.wrapper.setAttribute('role', 'listbox');
		this.wrapper.setAttribute('tabindex', '-1');
		this.wrapper.setAttribute('aria-hidden', 'true');
		this.options.forEach((option, index) => {
			option.setAttribute('role', 'option');
			option.setAttribute('tabindex', '-1');
			if (option.id === '') option.id = `${this.wrapper.id}-${index}`;

			if (option.getAttribute('aria-selected') === 'true') {
				this.focusedOption = index;
			}
		});
		this.currentValue =
			this.options[this.focusedOption].dataset.value ||
			this.options[this.focusedOption].value;
		this.wrapper.setAttribute(
			'aria-activedescendant',
			this.options[this.focusedOption].id
		);

		this.addEventListeners();
	};

	addEventListeners = () => {
		this.val.addEventListener('click', this.onSelectStateHandler);
		this.options.forEach((option) => {
			let EVENT = 'click';
			if (option.tagName === 'INPUT') {
				EVENT = 'change';
			}
			option.addEventListener(EVENT, (e) => this.onSelectChange(e), false);
			option.addEventListener('keydown', (e) => this.onSelectChange(e), false);
		});
	};

	onSelectState = () => {
		// close it
		if (this.val.getAttribute('aria-expanded') === 'true') {
			this.closeDropdown();
			this.val.focus();
		} else {
			// open it
			this.val.setAttribute('aria-expanded', 'true');
			this.wrapper.removeAttribute('aria-hidden');
			document.addEventListener('click', this.globalClose, false);
			document.addEventListener('keydown', this.keydownHandler);
		}
	};

	onSelectChange = (e, onChange = this.onChange) => {
		const keys = ['Enter', ' '];
		const types = ['click', 'change'];

		if (keys.includes(e.key) || types.includes(e.type)) {
			const newValue = e.currentTarget.href || e.currentTarget.value;

			// if you picked the same value again, do nothing and close the dropdown
			if (this.currentValue === newValue) {
				this.closeDropdown();
				return;
			}

			this.options.forEach((option) => {
				option.setAttribute('aria-selected', 'false');
				// because only checkboxes (not radios) are allowed as role=option, we need to uncheck the other checkboxes
				if (option.tagName === 'INPUT') {
					option.checked = false;
				}
			});

			this.val.querySelector('span').textContent =
				e.currentTarget.textContent || e.currentTarget.dataset.label;
			e.currentTarget.setAttribute('aria-selected', 'true');
			if (e.currentTarget.tagName === 'INPUT') {
				e.currentTarget.checked = true;
			}

			this.focusedOption = Array.from(this.options).indexOf(e.currentTarget);
			this.currentValue = newValue;
			// Update the aria-activedescendant value on the listbox to the ID of the option the user just interacted with
			this.wrapper.setAttribute(
				'aria-activedescendant',
				this.options[this.focusedOption].id
			);

			this.closeDropdown();

			if (typeof onChange === 'function') {
				onChange(e.currentTarget);
			}
		}
	};

	globalClose = (e) => {
		// skip if you click on the button or its children
		if (
			e.target === this.val ||
			Array.from(this.val.children).indexOf(e.target) > -1
		)
			return;

		this.closeDropdown();
	};

	keydownHandler = (e) => {
		// Spacebar or Enter on a button triggers a click event but 0 X & Y coordinates
		if (e.currentTarget.tagName === 'BUTTON') {
			if (e.type === 'click' && e.x === 0 && e.y === 0) {
				e.preventDefault();
			}
		}

		if (e.key === 'Escape' || e.key === 'Esc') {
			this.closeDropdown();
			this.val.focus();
		}

		if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
			// disabling the scrolling of the browser when using arrow keys
			e.preventDefault();

			if (e.key === 'ArrowDown') this.focusedOption++;
			if (e.key === 'ArrowUp') this.focusedOption--;
			// loop thru to the last option
			if (this.focusedOption < 0) this.focusedOption = this.options.length - 1;
			// loop thru to the first option
			if (this.focusedOption >= this.options.length) this.focusedOption = 0;
		}

		// pressing Tab will bring you to the first option
		if (e.key === 'Tab') {
			e.preventDefault(); // override default Tab behaviour
			this.focusedOption = 0;
		}

		if (e.key === 'Home') {
			e.preventDefault();
			this.focusedOption = 0;
		}

		if (e.key === 'End') {
			e.preventDefault();
			this.focusedOption = this.options.length - 1;
		}

		// highlight the chosen option
		this.options[this.focusedOption].focus();
	};

	closeDropdown = () => {
		this.options[this.focusedOption].blur();
		this.val.setAttribute('aria-expanded', 'false');
		this.wrapper.setAttribute('aria-hidden', 'true');
		document.removeEventListener('keydown', this.keydownHandler);
		document.removeEventListener('click', this.globalClose, false);
	};
}
