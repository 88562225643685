import Swimlane, {
	SWIMLANE_POSITION_STORAGE_KEY,
} from '../../classes/swimlane/swimlane';
import { isMobile } from '../../utils/breakpoints/breakpoints';
import { debounce } from '../../utils/throttle/debounce';
import { getViewportWidth } from '../../utils/window/window';
import tracking, {
	sendSwimlaneItemsInVisibleGroupImpressionTracking,
} from '../tracking/tracking.logic';
import swimlaneDetail from './swimlane.detail';

window.dataLayer = window.dataLayer || [];
window.dataLayer.teasers = window.dataLayer.teasers || [];

let observer,
	teaserObserver,
	windowWidth,
	swimlaneInstances = [],
	observedSwimlanes = [],
	fullscreenchangeevent;
// you can optionally pass a collection of elements
function init(elements) {
	observer = null;
	teaserObserver = null;
	fullscreenchangeevent = false;

	const swimlanes = elements
		? elements
		: document.querySelectorAll('x-swimlane');
	windowWidth = getViewportWidth();

	if (!swimlaneInstances.length && !observedSwimlanes.length) {
		swimlaneInstances = [];
		observedSwimlanes = [];
	} else {
		// if we "manually" re-init a swimlane, we need to remove the previous instances
		elements.forEach((el) => {
			if (observedSwimlanes.indexOf(el)) {
				// remove the swimlane instance from the array
				observedSwimlanes.splice(observedSwimlanes.indexOf(el), 1);
			}

			if (
				swimlaneInstances.some(
					(instance) => instance.elements.root.id === el.id
				)
			) {
				// remove the swimlane instance from the array
				swimlaneInstances.splice(
					swimlaneInstances.findIndex(
						(instance) => instance.elements.root.id === el.id
					),
					1
				);
			}
		});
	}

	if (swimlanes.length && !isMobile()) {
		createSwimlaneObserver(swimlanes);
	}

	let teasers = document.querySelectorAll('x-swimlane__item');
	createTeaserObserver(teasers);

	document.addEventListener('fullscreenchange', fullscreenchanged);
	window.addEventListener('resize', debounce(resizeHandler, 500), {
		passive: true,
	});

	swimlaneDetail.init(); //swimlane detail pages tracking
}

function createSwimlaneObserver(elements) {
	if (
		'IntersectionObserver' in window &&
		'IntersectionObserverEntry' in window &&
		'isIntersecting' in window.IntersectionObserverEntry.prototype
	) {
		observer = new IntersectionObserver(onSwimlaneIntersection, {
			threshold: [0.0, 0.5],
		});

		elements.forEach((el) => {
			observer.observe(el);
		});
	}
}

function createTeaserObserver(teasers) {
	if (
		'IntersectionObserver' in window &&
		'IntersectionObserverEntry' in window &&
		'isIntersecting' in window.IntersectionObserverEntry.prototype
	) {
		teaserObserver = new IntersectionObserver(onTeaserIntersection, {
			threshold: [0.5],
		});

		teasers.forEach((el) => {
			teaserObserver.observe(el);
		});
	}
}

function onTeaserIntersection(entries) {
	entries.forEach((entry) => {
		const swimlane = entry.target.closest('x-swimlane');
		let swimlaneTrackingOjbects = JSON.parse(
			swimlane && swimlane.getAttribute('data-tracking')
		);

		if (entry.isIntersecting) {
			let trackingModel = {
				swimlane: swimlaneTrackingOjbects,
				teasers: [],
			};

			const tracking =
				entry.target.querySelector('[js-element~="tracking"]') ||
				entry.target.querySelector('[js-element~="teaserImpressionTracking"]');
			const teaserData = tracking && tracking.getAttribute('data-tracking');

			teaserObserver.unobserve(entry.target);

			if (!teaserData) return;

			trackingModel.teasers.push(JSON.parse(teaserData));
			sendSwimlaneItemsInVisibleGroupImpressionTracking(trackingModel);
		}
	});
}

function onSwimlaneIntersection(entries) {
	entries.forEach((entry) => {
		if (entry.isIntersecting) {
			if (entry.intersectionRatio >= 0) {
				if (observedSwimlanes.indexOf(entry.target) === -1) {
					observedSwimlanes.push(entry.target);
					swimlaneInstances.push(new Swimlane(entry.target));
				}

				if (entry.target.dataset.impressionTracking === 'false') {
					observer.unobserve(entry.target);
				}
			}

			if (
				entry.intersectionRatio >= 0.5 &&
				entry.target.dataset.impressionTracking !== 'false'
			) {
				// start tracking
				tracking.init(entry.target);
				// once observed and tracked, now stop observing that entry
				observer.unobserve(entry.target);
			}
		}
	});
}

function fullscreenchanged() {
	fullscreenchangeevent = true;
}

function resizeHandler() {
	if (fullscreenchangeevent) {
		fullscreenchangeevent = false;
		return;
	}

	// detect only changes in width, not height
	if (getViewportWidth() !== windowWidth) {
		window.sessionStorage.removeItem(SWIMLANE_POSITION_STORAGE_KEY);

		if (isMobile()) {
			swimlaneInstances.forEach((instance) => {
				instance.destroy(true);
			});
		} else {
			observedSwimlanes?.forEach((swimlane) => {
				new Swimlane(swimlane);
			});

			if (observedSwimlanes.length === 0) {
				const swimlanes = document.querySelectorAll('x-swimlane');

				if (swimlanes.length && !isMobile()) {
					createSwimlaneObserver(swimlanes);
				}
			}
		}
	}

	windowWidth = getViewportWidth();
}

// update/destroy for the Episode swimlanes
function switchEpisodeSwimlanes(swimlanes) {
	swimlaneInstances.forEach((swimlane) => {
		swimlane.destroy(true);
	});

	init(swimlanes);
}

export default {
	init,
	switchEpisodeSwimlanes,
};
