let fadeElement;

function init() {
	// if scroll-driven animations are not supported, we need to do this manually
	if (!window.ScrollTimeline) {
		fadeElement = document.querySelector('[js-module~="detailFade"]');

		if (fadeElement) {
			if (window.scrollY > 0) {
				setOpacity(window.scrollY);
			}

			document.addEventListener(
				'scroll',
				() => {
					setOpacity(window.scrollY);
				},
				{ passive: true }
			);
		}
	}
}

function setOpacity(pos) {
	if (0 < pos <= 100) {
		fadeElement.style.opacity = 1 - pos / 125;
	} else if (pos > 100) {
		fadeElement.style.opacity = 0.2;
	} else {
		fadeElement.style.opacity = 1;
	}
}

export default {
	init,
};
